<template>
  <div class="container dynamic-page">
    <!-- 搜索 -->
    <div class="search content">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-row>
          <el-col :span="14">
            <el-form-item>
              <el-select
                v-model="queryForm.termName"
                placeholder="学期"
                style="width: 188px"
                @change="handleChange"
              >
                <el-option
                  v-for="(item, index) in semesterlist"
                  :key="index"
                  :label="item.termName"
                  :value="item.termName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" style="text-align: right">
            <el-form-item>
              <el-button type="primary" @click="handleRelease">发布</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 动态 -->
    <div class="dynamic content" v-for="(item, index) in dynamic" :key="index">
      <div class="avatar">
        <el-avatar
          v-if="item.userInfo[0] && item.userInfo[0] != 'null'"
          :src="imgSrc + item.userInfo[0]"
        ></el-avatar>
        <el-avatar
          v-else
          src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
        ></el-avatar>
        <div class="info">
          <h4>{{ item.userInfo[1] }}</h4>
          <p>{{ item.createTime.dateStr }} {{ item.createTime.time }}</p>
        </div>
      </div>
      <div class="article">
        <div class="art-cont" v-html="item.content"></div>
        <div class="art-image">
          <media-exhi v-if="item.imagesUrl" :file-data="item.imagesUrl"></media-exhi>
        </div>
        <div class="art-kind">
          <div class="kind kindC">
            {{item.indexOneName}} - {{ item.indexThreeName }}
          </div>
          <div class="operation">
			<span
			  :class="{ ques: item.isQuestion == 1 }"
			  @click="clickQues(item, index)"
			  ><i class="el-icon-question"></i> {{ item.questionNum }}</span
			>
			<span class="padd">|</span>
            <span
              :class="{ dianzan: item.isDianZan !== '0' }"
              @click="handleDianzan(item)"
              ><i class="iconfont icon-dianzan"></i> {{ item.dianZanNum }}</span
            >
            <span class="padd">|</span>
            <span @click="openComment(item, index, {}, -1,  1)" ref="showInput"
              ><i class="iconfont icon-tubiaozhizuomoban"></i
            ></span>
          </div>
        </div>
        <!-- 评论内容 -->
        <div class="discuss" v-if="item.comments.length > 0">
          <ul>
            <li v-for="(el, elin) in item.comments" :key="elin">
				<div class="avatar">
				  <el-image
					v-if="el[1] && el[1] != 'null'"
					:src="imgSrc + el[1]"
					fit="cover"
					style="width: 40px;height: 40px;"
				  ></el-image>
				  <el-image
					v-else
					src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
					fit="cover"
					style="width: 40px;height: 40px;"
				  ></el-image>
			  </div>
              <div class="info">
                <h4>
                  <span>{{ el[2] }}</span>：{{ el[4] }}
                </h4>
                <div style="display: flex; align-items: center">
                  <p>{{ el[3] ? el[3].dateStr : '' }} {{ el[3] ? el[3].time : '' }}</p>
                  <el-button
                    v-if="is_self(el[0])"
                    size="mini"
                    type="text"
                    style="margin-left: 10px; color: #999999"
                    @click="removeComment(item, index, el, elin, 1)"
                    >删除</el-button
                  >
				  <el-button size="mini" type="text" style="margin-left: 10px; color: #999999"  ref="showInput" @click="openComment(item, index, el, elin, 2)">回复</el-button>
                </div>
				<!-- 2级评论 -->
				<div class="comments-2th" v-if="show2thCmtZone(el)">
					<ul>
					  <li v-for="(cmt, cmtIdx) in el[6].list" :key="cmtIdx">
						<div class="avatar">
							<el-image
							  v-if="cmt[1] && cmt[1]!='null'"
							  :src="imgSrc + cmt[1]"
							  fit="cover"
							  style="width: 40px;height: 40px;"
							></el-image>
							<el-image
							  v-else
							  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
							  fit="cover"
							  style="width: 40px;height: 40px;"
							></el-image>
						</div>
					    <div class="info">
					      <h4>
					        <span>{{ cmt[2] }}</span
					        >：{{ cmt[4] }}
					      </h4>
					      <div style="display: flex; align-items: center">
					       <p>{{ cmt[3] ? cmt[3].dateStr : '' }} {{ cmt[3] ? cmt[3].time : '' }}</p>
					        <el-button v-if="is_self(cmt[0])"  size="mini" type="text" style="margin-left: 10px; color: #999999" @click="removeComment(item, index, el, elin, 2, cmt, cmtIdx)">删除</el-button>
							<el-button size="mini" type="text" ref="showInput" style="margin-left: 10px; color: #999999" @click="openComment(item, index, el, elin, 3, cmt, cmtIdx)">回复</el-button>
					      </div>
					    </div>
					  </li>
					</ul>
					<span class="see-more-cmts" v-if="showLoadMore2ndCmt(el)" @click="getMore2ndComments(item, index, el, elin)"><i class="el-icon-plus"></i>查看更多回复</span>
				</div>
              </div>
            </li>
          </ul>
		  <span class="see-more-cmts" @click="getMore1stComments(item, index)" v-if="showLoadMoreCmt(item, index)"><i class="el-icon-plus"></i>查看更多评论</span>
        </div>
        <!-- 打开评论 -->
        <div class="comment">
          <div class="comment-cont" ref="showInput" v-if="item.isComments">
            <div class="comment-text">
              <el-form
                :model="form"
                :rules="rules"
                ref="commentsForm"
                class="demo-ruleForm"
              >
                <el-form-item prop="commentContent">
                  <el-input
                    type="textarea"
                    :rows="3"
                    v-model="commentContent"
					:placeholder="commentPh"
                    resize="none"
					:id="`cometEditor${index}`"
                    v-focus
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="comment-btn">
			  <el-popover placement="left-end" v-model="showEmoji">
					<template>
						<ul class="emoji-list">
							<li v-for="(item,idx) in emojiList" :key="idx" @click="inputEmoji(idx, index)">{{ item.char }}</li>
						</ul>
					</template>
					<span slot="reference" class="iconfont icon-xiaolian" style="font-size: 20px;"></span>
			  </el-popover>
              <div>
				  <el-button type="primary" v-if="cmtMultiGrade.grade == 1" @click="submitComments(item, index)">评论</el-button>
				  <el-button type="text" v-if="cmtMultiGrade.grade != 1" @click="openComment(item, index, {}, -1,  1)">切换至评论</el-button>
				  <el-button type="success" v-if="cmtMultiGrade.grade != 1" @click="submitReply(item.id)">回复</el-button>
              </div>
            </div>
          </div>
          <div class="comment-none" @click="openComment(item, index, {}, -1, 1)" v-else>
            评论
          </div>
        </div>
      </div>
    </div>

    <!-- 发布 -->
    <el-dialog
      title="发布个人写实记录"
      :visible.sync="open"
      width="600px"
			@opened="initEditor"
      center
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
        <el-form-item prop="content" label="内容：">
          <div id="editor" ref="editor"></div>
        </el-form-item>
        <el-form-item label="附件：">
          <el-upload
            :action="upload.action"
            :accept="upload.accept"
            :limit="upload.limit"
            :multiple="upload.multiple"
            :file-list="upload.list"
            :on-change="uploadChange"
            :on-remove="uploadRemove"
            :auto-upload="upload.auto"
          >
            <el-button size="small" type="primary" v-if="upload.list.length < 3">添加附件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="标签选择" prop="indexThreeId">
          <el-select
            v-model="form.indexOneId"
            placeholder="一级指标"
            style="width: 100px"
            @change="getIndexTwo"
          >
            <el-option
              v-for="(item, index) in indexOne"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="form.indexThreeId"
            placeholder="二级指标"
            style="width: 200px; margin-left: 15px"
			@change="getIndexTwoId"
          >
            <el-option
              v-for="(item, index) in indexTwo"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
		  <el-button size="small" type="text" style="margin-left: 20px;" :disabled="!form.indexThreeId" @click="getExample">查看示例</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancle">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </div>
    </el-dialog>
	<!-- 质疑提交 -->
	<el-dialog
		title="质疑"
	    :visible.sync="dialog.quesForm"
	    width="600px"
		center
	>
		<el-form :model="quesForm">
			<el-form-item label="质疑类型：" prop="questionCode">
				<el-radio-group v-model="quesForm.questionCode">
					<p class="ques-tp" v-for="(item, idx) in constData.quesTp" :key="idx">
						<el-radio :label="item.code">{{ item.name }}</el-radio>
					</p>
				  </el-radio-group>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
		  <el-button @click="closeDialog('quesForm')">取 消</el-button>
		  <el-button type="primary" :disabled="!quesForm.questionCode" @click="submitQues">提交</el-button>
		</div>
	</el-dialog>
	<!-- 质疑详情 -->
	<el-dialog
		title="发布个人写实记录"
	    :visible.sync="dialog.quesDetail"
	    width="600px"
		center
	>
		
	</el-dialog>
	<!-- 写实记录示例 -->
	<el-dialog
	title="示例详情"
	:visible.sync="dialog.example"
	width="600px"
	center>
		<el-image :src="example.url" fit="cover" :preview-src-list="example.preview"></el-image>
		<p style="text-align: right; color: #999; font-size: 12px;">点击图片可放大</p>
		<div slot="footer">
			<el-button type="primary" @click="closeDialog('example')">关闭</el-button>
		</div>
	</el-dialog>
  </div>
</template>

<script>
import {
  getIndex,
  getIndexThree,
  addRecord,
  getAllSemester,
  getTeacherClass,
  getPerRecord,
  updateStatus,
  getDianZan,
  addComments,
  getComments,
  removeComments,
  updateQuesNum,
  getQuesCdNum,
  addQuesComets,
  getQuesComets,
  getExample,
  cmt2cmt
} from "@/api/record/personal/personal"
import { getDic } from "@/api/public/search"
import { getToken } from "@/utils/auth"
import E from "wangeditor"
import mediaExhi from "@/components/mediaExhibition.vue"
import { dynamicFn } from "@/mixin/dynamic/index.js"
import { dynamicStuPersonal } from "@/mixin/dynamic/personal/student.js"
export default {
	components: {
		mediaExhi
	},
	mixins: [ dynamicFn, dynamicStuPersonal ],
  data() {
    return {
      queryForm: {
        pageSize: 15,
        pageNum: 1,
        deptId: "",
        kind: "0",
        auditStatus: "",
        termName: "",
        userName: "",
      },
      pageTotal: 0,
      open: false,
      comment: "",
      semesterlist: [],
      dynamic: [],
      indexOne: [],
      indexTwo: [],
      indexThree: [],
      form: {
        indexRuleLevel: "0",
        file: [],
      },
	  dialog: {
		quesForm: false,
		quesDetail: false,
		example: false
	  },
	  constData: {
		  quesTp: []
	  },
	  quesForm: {
		questionCode: '',
		id: '',
		idx: 0
	  },
	  example: {
		url: '',
		preview: []
	  },
      rules: {
        indexThreeId: [
          { required: true, message: "请选择指标标签", trigger: "change" },
        ],
        // commentContent: [
        //   { required: true, message: "请输入内容或表情", trigger: "blur" },
        // ],
      },
      commentContent: "",
	  commentPh: '',
	  editor: {},
	  upload: {
		action: '',
		accept: '.jpg,.png,.doc,.docx,.xls,.xlsx,.pdf,.txt',
		limit: 3,
		multiple: false,
		list: [],
		auto: false
	  }
    };
  },
  created() {
    this.semester();
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (this.$refs.showInput) {
        let isSelf;
        let showArr = this.$refs.showInput
        for (let i = 0; i < showArr.length; i++) {
        	let v = showArr[i].$el ? showArr[i].$el : showArr[i]
          if (v.contains(e.target)) {
            isSelf = v.contains(e.target)
          }
        }
        if (!isSelf) {
		  this.commentContent = "";
          this.dynamic.map((item, idx) => {
			item.isComments = false;
          });
		  this.showEmoji = false
        }
      }
    });
    document.addEventListener("scroll", this.Scrollbottom);
  },
  methods: {
	// 写实记录示例
	getExample() {
		getExample({
			threeIndexId: this.form.indexThreeId
		}).then(res => {
			if (res.data) {
				this.example.url = this.imgSrc + res.data.fileUrl
				this.example.preview.push(this.example.url)
				this.openDialog('example')
			} else {
				this.$message.info('暂无示例图片')
			}
		})
	},
    getList() {
      getPerRecord(this.queryForm).then((res) => {
        this.pageTotal = res.data.pages;
        this.dynamic = res.data.list;
        this.dynamic.map(async (item, idx) => {
          item.createTime = this.splitDate(item.createTime);
          item.isComments = item.isComments == "0" ? false : true;
          item.userInfo = item.userInfo.split(",")
		  item.comments = await this.process1stCommts(item, idx, [], [])
        })
      });
    },
    getListCount() {
      getPerRecord(this.queryForm).then((res) => {
        let arr = res.data.list;
        arr.map(async (item, idx) => {
          item.createTime = this.splitDate(item.createTime);
          item.isComments = item.isComments == "0" ? false : true;
          item.userInfo = item.userInfo.split(",")
          item.comments = await this.process1stCommts(item, idx, [], [])
		  this.dynamic.push(item)
        })
      })
    },
    handleChange(termName) {
      this.queryForm.termName = termName;
      this.getList();
    },
	// 质疑or取消
	clickQues(item, idx) {
		if (item.isQuestion == 0) {
			this.handleQuestion(item, idx)
		} else if (item.isQuestion == 1) {
			this.cancelQues(item, idx)
		}
	},
	// 打开质疑表单
	handleQuestion(item, idx) {
		this.quesForm.id = item.id
		this.quesForm.questionCode = ''
		this.quesForm.idx = idx
		getDic({
			type: 'recordQuestion'
		}).then(res => {
			this.constData.quesTp = res.data
			this.openDialog('quesForm')
		})
	},
	// 打开弹窗
	openDialog(nm) {
		this.dialog[nm] = true
	},
	// 关闭弹窗
	closeDialog(nm) {
		this.dialog[nm] = false
	},
	// 提交质疑
	submitQues() {
		updateQuesNum(this.quesForm).then(res => {
			this.$message.success('质疑提交成功')
			this.closeDialog('quesForm')
			let idx = this.quesForm.idx
			this.dynamic[idx].isQuestion = 1
			this.dynamic[idx].questionNum++
		})
	},
	// 取消质疑
	cancelQues(item, idx) {
		this.$confirm('取消质疑?', '取消质疑', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		}).then(() => {
			updateQuesNum({
				id: item.id
			}).then(res => {
				this.$message.success('取消质疑成功')
				this.dynamic[idx].isQuestion = 0
				this.dynamic[idx].questionNum--
			})
		})
	},
    // 点赞
    handleDianzan(item) {
      getDianZan({ id: item.id }).then((res) => {
        if (item.isDianZan == "0") {
          item.isDianZan = "1"
          item.dianZanNum++
        } else {
          item.isDianZan = "0"
          item.dianZanNum--
        }
      });
    },
    is_self(id) {
      return id == this.$store.getters.userId;
    },
    // 发布
    handleRelease() {
      this.open = true;
      this.reset();
      this.getIndexOne();
    },
    // 材料上传
    uploadChange(file, fileList) {
    	let rawTp = file.raw.type
    	// pdf, excel表格, word文档, 图, 纯文本
    	let allowTp = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'image/gif', 'image/png', 'image/jpeg', 'text/plain']
    	if (allowTp.indexOf(rawTp) == -1) {
    		this.$message.error("材料仅支持pdf、excel表格、word、图片与纯文本格式！")
    		fileList.pop()
    	} else {
    		this.upload.list.push(file.raw)
    		this.$message.success("上传成功")
    	}
    },
    // 材料删除
    uploadRemove(file, fileList) {
    	this.upload.list = fileList
    },
    handleSubmit() {
      if (this.form.file) {
        this.form.file = this.compressImg(this.form.file);
      }
	  let cont = this.editor.txt.text()
	  if (cont.length < this.realisticLen.min) {
	  	this.$message.warning(`发布内容至少输入${ this.realisticLen.min }个字`)
	  	return
	  } else if (cont.length > this.realisticLen.max) {
	  	this.$message.warning(`发布内容字数不得超过${ this.realisticLen.max }字，请知悉！`)
	  	return
	  }
      setTimeout(() => {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            var formDate = new FormData();
            formDate.append("content", this.editor.txt.html());
            formDate.append("indexOneId", this.form.indexOneId);
            formDate.append("indexTwoId", this.form.indexTwoId);
            formDate.append("indexThreeId", this.form.indexThreeId);
            formDate.append("indexThreeName", this.form.indexThreeName);
            formDate.append("indexRuleLevel", this.form.indexRuleLevel);
						if (this.upload.list.length > 0) {
							this.upload.list.forEach((item) => {
								formDate.append("file", item);
							});
						}
            addRecord(formDate).then((res) => {
              this.$message.success("发布成功");
              this.cancle();
							this.queryForm.pageNum = 1
              this.getList();
              this.resetForm("form");
            });
          }
        });
      }, 1000);
    },
    cancle() {
      this.open = false;
      this.reset();
    },
    reset() {
      this.form = {
        content: undefined,
        indexOneId: undefined,
        indexTwoId: undefined,
        indexThreeId: undefined,
        indexThreeName: undefined,
        indexRuleLevel: 0,
        file: [],
        fileList: [],
      };
	  this.upload.list = []
      this.resetForm("form");
    },
    // 获取学期
    semester() {
      getAllSemester({}).then((res) => {
        this.semesterlist = res.data;
        this.queryForm.termName = this.semesterlist[0].termName;
        this.getList();
      });
    },
    // 获取指标
    getIndexOne(id = 0) {
      getIndex({ id: id }).then((res) => {
        this.indexOne = res.data;
      });
    },
    getIndexTwo(id) {
      this.form.indexTwoId = "";
      this.form.indexThreeId = "";
      getIndex({ id: id }).then((res) => {
        this.indexTwo = res.data;
      });
    },
	// 获取3级指标后取二级指标id
	getIndexTwoId(v) {
		this.indexTwo.forEach(item => {
			if (item.code == v) {
				this.form.indexThreeName = item.name
				this.form.indexTwoId = item.parentId
			}
		})
	},
    getIndexThree(id) {
      this.form.indexThreeId = "";
      getIndexThree({ id: id }).then((res) => {
        this.indexThree = res.data;
        if (this.indexThree.length < 1) {
          this.$message.warning("该指标项下没有写实记录，请更换二级指标");
        }
      });
    },
    getIndexThreeName(id) {
      this.indexThree.forEach((item) => {
        if (item.id == id) {
          this.form.indexThreeName = item.name;
        }
      });
    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight - 300) {
        // console.log("滚动到底部了")
        if (this.queryForm.pageNum < this.pageTotal) {
          this.queryForm.pageNum++;
          this.getListCount();
        }
      }
      // console.log(num)
    },
	// 初始化编辑器
	initEditor(editorId) {
		var that = this;
		// 销毁已有editor实例
		if (!that.isEmpty(that.editor.id)) {
			that.editor.destroy()
			that.editor = null
		}
		that.editor = new E(that.$refs.editor)
		// 编辑器的事件，每次改变会获取其html内容
		that.editor.config.onchange = (html) => {
		  that.form.content = html
		}
		that.editor.config.height = 300;
		that.editor.config.showLinkImg = false;
		that.editor.config.debug = true;
		that.editor.config.uploadImgServer =
		  process.env.VUE_APP_API + "/shida-user-biz/upload/uploadImage";
		that.editor.config.uploadFileName = "file";
		that.editor.config.placeholder = "请输入写实记录内容"
		that.editor.config.uploadImgHeaders = {
		  Authorization: getToken(),
		  'role-distinction-client': 2
		};
		that.editor.config.uploadImgHooks = {
		  // 图片上传并返回结果，但图片插入错误时触发
		  fail: function (xhr, editor, result) {
		    console.error(result);
		  },
		  success: function (xhr, editor, result) {
		    // 图片上传并返回结果，图片插入成功之后触发
		  },
		  // 图片上传并返回了结果，想要自己把图片插入到编辑器中
		  // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
		  customInsert: function (insertImgFn, result) {
		    // result 即服务端返回的接口
		    // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
		    insertImgFn(that.imgSrc + result.data);
		  },
		};
		that.editor.config.menus = [
		  // 菜单配置
		  "bold", // 粗体
		  "fontSize", // 字号
		  "italic", // 斜体
		  "underline", // 下划线
		  "strikeThrough", // 删除线
		  "foreColor", // 文字颜色
		  "backColor", // 背景颜色
		  "link", // 链接
		  "undo", // 撤销
		  "redo", // 重做
		];
		that.editor.create(); // 创建富文本实例
	}
  }
};
</script>

<style scoped>
.el-select >>> .el-input--suffix .el-input__inner {
  padding-left: 5px !important;
}
.el-textarea >>> .el-textarea__inner:focus {
  border: 1px solid #dcdfe6;
}
.el-textarea >>> .el-input__count {
  background: rgba(255, 255, 255, 0);
}
.el-form >>> .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.el-form >>> .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
.ques-tp {
	margin-top: 13px;
}
#editor {
	clear: both;
}
</style>